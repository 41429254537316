import * as React from "react";
import HcpLayout from "../components/layout";
import { Seo } from "../components/seo";
import FluidContent from "../components/fluidContent";
import { Link } from "gatsby";

const TermsOfServicePage = () => {
    return (
        <HcpLayout pageClass="terms-of-service-page">
            <FluidContent className='p-60'>
                <div className="centered-content">
                    <div className="content-wrap">
                        <h1>Terms Of Service</h1>
                        <p className="sub-head"><strong>1. Acceptance of the Terms and Conditions</strong></p>
                        <p>ARTBIO (“ARTBIO,” “we,” “us,” or “our”) provides and makes available this website (the “Website”). All use of the Website is subject to the terms and conditions contained in the Terms of Service (the “Agreement”). Please read this Agreement carefully. By accessing, browsing, or otherwise using the Website, you acknowledge that you have read, understood, and agree to be bound by this Agreement. If you do not accept the terms and conditions of this Agreement, you shall not, and are not permitted to, access, browse, or use the Website.</p>

                        <p>You understand and agree that we may change this Agreement at any time without prior notice. You may read a current, effective copy of this Agreement at any time by selecting the “Terms of Service” link on the Website. The revised terms and conditions will become effective at the time of posting. Any use of the Website after such date constitutes your acceptance of such revised terms and conditions. If any change to this Agreement is not acceptable to you, your sole remedy is to cease accessing, browsing, or otherwise using the Website.</p>

                        <p>Your access to and use of the Website is also subject to our <Link to='/privacy-policy'>Privacy Policy</Link>, which is hereby incorporated herein by reference.</p>

                        <p>Nothing on this Website should be construed as an offer to form a binding contract, or as granting any license or transfer of intellectual property. Your use of this Website is at your own risk.</p>

                        <p>PLEASE READ THIS AGREEMENT CAREFULLY, AS IT CONTAINS AN AGREEMENT TO ARBITRATE, WHICH REQUIRES THAT YOU AND ARTBIO ARBITRATE CERTAIN CLAIMS BY BINDING, INDIVIDUAL ARBITRATION INSTEAD OF GOING TO COURT, AND LIMIT CLASS-ACTION CLAIMS, UNLESS YOU OPT OUT OF THE AGREEMENT TO ARBITRATE AS DESCRIBED IN THE ARBITRATION SECTION (SEE SECTION 8 OF THESE TERMS).</p>

                        <p className="sub-head"><strong>2. Use of the Website</strong></p>
                        <p><u>Medical Information/Conditions</u>. Any information posted on this Website related to medical conditions and their treatment is general in nature and is intended only for educational and general information purposes. Should you have a medical condition that may require treatment, or if you have any specific medical questions, promptly see your own doctor or other healthcare provider. Nothing on this Website is intended to act as professional medical advice. If you are a patient in a medical emergency, please seek emergency treatment from a doctor or other healthcare provider instead of reviewing information on this Website.  We do not offer medical diagnosis or treatment from this Website, and none of the information contained on this Website should be construed as providing medical advice or services of any kind. Only your doctor or other healthcare provider can determine whether a particular medical treatment is appropriate for you.</p>

                        <p>Always seek the advice of your doctor or other qualified healthcare provider before starting any new treatment or with any question that you may have about a medical condition.</p>

                        <p><u>Content</u>. The structure, code and organization of this Website are proprietary to us. This Website contains material, including but not limited to, software, text, graphics, and images (collectively, the “Content”). We may own the Content or portions of the Content may be made available to us through arrangements that we have with one or more third parties. The Content is protected by United States and foreign intellectual property laws. Unauthorized use of any Content may violate copyright, trademark, or other laws. You have no rights in or to any Content, and you will not use, copy, or display the Content except as permitted in this Agreement. No other use is permitted without our prior written consent. You must retain all copyright and other proprietary notices contained in the original Content on any copy you make of the Content. You may not sell, transfer, assign, license, sublicense, or modify any Content or reproduce, display, publicly perform, make a derivative work of, distribute, or otherwise use any Content in any way for any purpose, including without limitation any public or commercial purpose. The use or posting of any Content on any other website or in a networked computer environment for any purpose is prohibited. If you violate any part of this Agreement, your right to access and/or use any Content or the Website shall automatically terminate, and you shall immediately destroy any copies you have made of any Content.</p>

                        <p><u>Trademarks</u>. The trademarks, service marks, and logos owned by ARTBIO that are used and displayed on this Website, including, without limitation, all such marks and logos referencing ARTBIO, are registered and unregistered trademarks or service marks of ARTBIO. Other company, product, and service names located on the Website may be trademarks or service marks owned by third parties (the “Third-Party Trademarks”, and, collectively with the ARTBIO Trademarks, the “Trademarks”). Nothing on this Website or in this Agreement should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any Trademark displayed on this Website without the prior written consent of ARTBIO specific for each such use. The Trademarks may not be used to disparage ARTBIO or the applicable third party, ARTBIO’s or the third party’s products or services, or in any manner (using commercially reasonable judgment) that may damage any goodwill in the Trademarks. Use of any Trademarks as part of a link to or from any website is prohibited without ARTBIO’s prior written consent. All goodwill generated from the use of any ARTBIO Trademark shall inure to ARTBIO’s benefit. All rights are reserved by the owners of each Trademark, except as otherwise described in this Agreement.</p>

                        <p><u>Certain Prohibitions</u>. You agree not to: (a) take any action that imposes an unreasonable load on the Website’s infrastructure; (b) use any device, software, or routine to interfere or attempt to interfere with the proper working of the Website or any activity being conducted on the Website; (c) attempt to decipher, decompile, disassemble, or reverse engineer any of the software comprising or making up the Website; (d) delete or alter any material posted on the Website by ARTBIO or any other person or entity; (e) frame or link to any of the materials or information available on the Website; or (f) distribute any virus, Trojan horse, disabling device, time bomb, or other code that may impact the operation of the Website, or use the Website to distribute any of the foregoing.</p>

                        <p><u>Third-Party Links</u>. The Website may contain links and/or references to third-party websites and/or services (the “External Sites”). These links are provided solely as a convenience to you and not as an endorsement by us of the content on such External Sites. The content of such External Sites is developed and provided by others. You should contact the site administrator or Webmaster for those External Sites if you have any concerns regarding such links or any content located on such External Sites. We are not responsible for the content of any External Site, and do not make any representation regarding the content or accuracy of any materials on any such External Site. You should take precautions when downloading files from all websites to protect your computer from viruses and other destructive programs. If you decide to access any External Site, you do so at your own risk.</p>

                        <p><u>Links to Us</u>. Links to any document published by us on this Website must be made to the home page only, without deleting any frames or our URL address.</p>

                        <p><u>Our Social Media</u>. We may use social media accounts from time to time. If you use any social media to communicate with us, please be aware that the terms and privacy policies of the applicable social media platform will govern those communications with us, in addition to this Agreement. Information that you make available on a social media platform may be made available publicly, so we recommend that you not provide us with any information via any social media that you do not feel comfortable making available publicly. We welcome your mentions, replies, comments, likes, suggestions, or shares, but please note that any content that you provide to us via a social media channel may be used by us without restriction and without any obligation of payment to you. We will use reasonable efforts to respond to your comments. We may remove or not approve a comment for any reason or no reason, including if it the comment includes vulgar, defamatory, racist, pornographic, violent, or other content. There are certain questions or comments that we will not respond to, including, without limitation, financial data or data on clinical trials. If we follow any other organization or person’s account, that is not an endorsement. We reserve all rights in relation to use of social media platforms. We do not offer medical diagnosis or treatment via our social media accounts.</p>

                        <p className="sub-head"><strong>3. Limitation of Liability and Disclaimer of Warranties</strong></p>

                        <p><u>Disclaimer of Warranties</u>. THE WEBSITE AND CONTENT ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND. ARTBIO, ITS AFFILIATES, THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, OR LICENSORS (COLLECTIVELY, THE “ARTBIO  PARTIES“) MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE CONTENT, INCLUDING, BUT NOT LIMITED TO, ITS ACCURACY, RELIABILITY, COMPLETENESS, TIMELINESS, OR RELIABILITY, OR THAT ARTBIO WILL UPDATE SUCH CONTENT OR KEEP SUCH CONTENT CURRENT OR UP TO DATE. THE ARTBIO PARTIES SHALL NOT BE SUBJECT TO LIABILITY FOR THE TRUTH, ACCURACY, OR COMPLETENESS OF THE CONTENT OR ANY OTHER INFORMATION CONVEYED TO YOU; FOR ERRORS, MISTAKES, OR OMISSIONS THEREIN; OR FOR ANY DELAYS OR INTERRUPTIONS OF THE DATA OR INFORMATION STREAM FROM WHATEVER CAUSE. YOU AGREE THAT YOU USE THE WEBSITE AND THE CONTENT AT YOUR OWN RISK. THE ARTBIO PARTIES DO NOT WARRANT THAT THE WEBSITE WILL OPERATE ERROR-FREE OR THAT THE WEBSITE, ITS SERVER, OR THE CONTENT ARE FREE OF COMPUTER VIRUSES OR SIMILAR CONTAMINATION OR DESTRUCTIVE FEATURES. IF YOUR USE OF THE WEBSITE OR THE CONTENT RESULTS IN THE NEED FOR SERVICING OR REPLACING EQUIPMENT OR DATA, NO ARTBIO PARTY SHALL BE RESPONSIBLE FOR THOSE COSTS. THE ARTBIO PARTIES DISCLAIM ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTY OF TITLE, MERCHANTABILITY, NON-INFRINGEMENT, OR FITNESS FOR PARTICULAR PURPOSE.</p>

                        <p><u>Limitation of Liability</u>. IN NO EVENT SHALL ANY ARTBIO  PARTY BE LIABLE FOR ANY INCIDENTAL, CONSEQUENTIAL, MULTIPLE, PUNITIVE, SPECIAL, OR OTHER INDIRECT DAMAGES, LOST PROFITS, LOST REVENUES, OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION RESULTING FROM THE USE OR INABILITY TO USE THE WEBSITE OR ANY CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, EVEN IF SUCH ARTBIO  PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE ARTBIO PARTIES’ AGGREGATE LIABILITY FOR ALL CLAIMS ARISING FROM THIS AGREEMENT SHALL NOT EXCEED THE GREATER OF (I) $1.00 OR (II) THE AGGREGATE AMOUNT YOU HAVE PAID TO US FOR THIS WEBSITE, IF ANY, IN THE THEN-PRIOR TWELVE (12) MONTH PERIOD.</p>

                        <p><u>Certain States</u>. Some states do not allow exclusion of implied warranties or limitation of liability for incidental or consequential damages, so the above limitations or exclusions may not apply to you. IN SUCH STATES, THE LIABILITY OF THE ARTBIO PARTIES SHALL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.</p>

                        <p className="sub-head"><strong>4. Indemnification</strong></p>

                        <p>You agree to defend, indemnify, and hold harmless the ARTBIO Parties from and against any claims, actions, or demands, including, without limitation, reasonable legal and accounting fees arising or resulting from your breach of this Agreement or your access to, use of, or misuse of any Content or the Website. ARTBIO will provide notice to you of any such claim, suit, or proceeding. ARTBIO reserves the right to assume the exclusive defense and control of any matter that is subject to indemnification under this section. In such case, you agree to cooperate with any reasonable requests assisting ARTBIO’s defense of such matter.</p>

                        <p className="sub-head"><strong>5. Termination</strong></p>

                        <p>ARTBIO reserves the right, in its sole discretion, to restrict, suspend, or terminate this Agreement and your access to all or any part of the Website or the Content at any time and for any reason without prior notice or liability. ARTBIO reserves the right to change, suspend, or discontinue all or any part of the Website or the Content at any time without prior notice or liability.</p>

                        <p>Sections 2 (Use of the Website), 3 (Limitation of Liability and Warranty), 4 (Indemnification), 5 (Termination of Agreement), and 9 (General) shall survive the termination of this Agreement.</p>

                        <p className="sub-head"><strong>6. User Must Comply with Applicable Laws</strong></p>

                        <p>We make no claims concerning whether the Content may be downloaded, viewed, or be appropriate for use outside of the United States. If you access the Website or the Content from outside of the United States, you do so at your own risk. Whether inside or outside of the United States, you are solely responsible for ensuring compliance with the laws of your specific jurisdiction. You understand that if you access this website from another country and provide your personal information to us through this website, your personal information may be transmitted to us, and you consent to such transfer.</p>

                        <p>The United States controls the export of products and information. You expressly agree to comply with such restrictions and not to export or re-export any of the Content to countries or persons prohibited under the export control laws. By downloading any Content, you are expressly agreeing that you are not in a country where such export is prohibited or are a person or entity for which such export is prohibited. You are solely responsible for compliance with the laws of your specific jurisdiction regarding the import, export, or re-export of any Content.</p>

                        <p className="sub-head"><strong>7. U.S. Government-Restricted Rights</strong></p>

                        <p>The Content is provided with “RESTRICTED RIGHTS.” Use, duplication, or disclosure by the Government is subject to the restrictions contained in 48 CFR 52.227-19 and 48 CFR 252.227-7013 et seq. or its successor. Use of the Website or any Content by the Government constitutes acknowledgement of our proprietary rights on the Website and Content.</p>

                        <p className="sub-head"><strong>8. Arbitration</strong></p>

                        <p>If at any time a dispute, controversy, or claim arising out of or in connection with this Agreement, its interpretation, construction, or performance, or the breach or enforcement thereof, or any claims or disputes that in any way concern the conduct of any Party in connection with this Agreement or otherwise (whether in contract, in tort, or based on statute or regulation) shall arise, then any Party shall give notice to the other Party in writing of the existence of such dispute, controversy, or claim, specifying its nature and the point of issue. The Parties shall have sixty (60) days from the date of such notice, or such later period as may be agreed, to resolve such dispute, controversy, or claim amicably by mediation.</p>

                        <p>Disputes, controversies, or claims among the Parties that remain unresolved at the expiration of the period specified in the previous paragraph may be referred by either Party to and shall finally and exclusively be settled by arbitration.</p>

                        <p>The arbitration shall be exclusively conducted in accordance with the Arbitration Rules of the International Chamber of Commerce in New York, New York (the “ICC Rules”), which are deemed to be incorporated by reference herein. The number of arbitrators shall be determined in accordance with the ICC Rules. If the number of arbitrators is three (3,) then each Party shall have the right to appoint one (1) arbitrator and the arbitrators shall mutually appoint the third arbitrator. The seat, or legal place, of arbitration shall be in New York City, State of New York, USA, and the language of the arbitration shall be English.</p>

                        <p>The arbitral proceedings and the arbitral award shall be kept strictly confidential. This confidentiality obligation shall cover all information disclosed in the course of such arbitral proceedings. This obligation to observe confidentiality shall continue ten (10) years after the termination of the Agreement, or ten (10) years after the case is finally settled by an arbitral award, whichever is the latest. This confidentiality undertaking shall cover all information disclosed in the course of such arbitral proceedings.</p>

                        <p className="sub-head"><strong>9. General</strong></p>

                        <p>This Agreement constitutes the entire agreement between you and ARTBIO and governs your use of the Website, superseding any prior agreements between you and ARTBIO with respect to the Website. You also may be subject to additional terms and conditions that may apply when you use affiliate services, third-party content, or third-party software. EACH OF THE PARTIES HEREBY KNOWINGLY, VOLUNTARILY, AND INTENTIONALLY WAIVES ANY RIGHT IT MAY HAVE TO A TRIAL BY JURY IN RESPECT OF ANY LITIGATION (INCLUDING BUT NOT LIMITED TO ANY CLAIMS, COUNTERCLAIMS, CROSS-CLAIMS, OR THIRD-PARTY CLAIMS) ARISING OUT OF, UNDER, OR IN CONNECTION WITH THIS AGREEMENT. FURTHER, EACH PARTY HERETO CERTIFIES THAT NO REPRESENTATIVE OR AGENT OF EITHER PARTY HAS REPRESENTED, EXPRESSLY OR OTHERWISE, THAT SUCH PARTY WOULD NOT, IN THE EVENT OF SUCH LITIGATION, SEEK TO ENFORCE THIS WAIVER OF RIGHT TO JURY TRIAL PROVISION. EACH OF THE PARTIES ACKNOWLEDGES THAT THIS SECTION IS A MATERIAL INDUCEMENT FOR THE OTHER PARTY ENTERING INTO THIS AGREEMENT.</p>

                        <p>The failure of ARTBIO to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision. If any provision of this Agreement is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of this Agreement shall remain in full force and effect. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Website or this Agreement must be filed within one (1) year after such claim or cause of action arose or be forever barred. A printed version of this Agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. The section titles in this Agreement are for convenience only and have no legal or contractual effect. Notices to you may be made via either email or regular mail. The Website may also provide notices to you of changes to this Agreement or other matters by displaying notices or links to notices generally on the Website.</p>

                        <p>If you are a California resident, we are required to inform you that you may reach the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs via mail at 1625 North Market Blvd., Suite N112, Sacramento, CA 95834 or telephone at 916-445-1254 or 800-952-5210.  Hearing-impaired users can reach the Complaint Assistance Unit at TDD 800-326-2297 or TDD 916-322-1700.</p>

                        <p>Copyright © 2023, ARTBIO, All Rights Reserved.</p>

                        <p><u>Updated</u>: April 25, 2023</p>
                    </div>
                </div>
            </FluidContent>
        </HcpLayout>
    );
};

export default TermsOfServicePage;

export const Head = () => <Seo title="Terms Of Service" />;
